<div *ngFor="let value of arry" fxLayout="row" fxLayoutAlign="start start" style="margin: 0; padding: 0;">
  <div *ngIf="icon" [fxFlex]="icon ? '5%' : '0'" [fxFlex.xs]="icon ? '10' : '0'" style="margin: 0; padding: 0;">
    <mat-icon style="padding: 5px 0px;" class="maticoncss">{{ matIcon }}</mat-icon>
  </div>
  <div [fxFlex]="icon ? '95%' : '100%'" style="margin: 0; padding: 0;">
    <calsoft-editor-text-child
      [data]="data"
      [text]="value"
      [align]="align"
      [fontSize]="fontSize"
      [MobfontSize]="MobfontSize"
      [fontFamily]="fontFamily">
    </calsoft-editor-text-child>
  </div>
</div>