import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'calsoft-editor-text',
  templateUrl: './editor-text.component.html',
  styleUrls: ['./editor-text.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditorTextComponent implements OnInit {
  trustedHtmlContent: any;
  @Input() text: any;

  @Input() align: any;

  @Input() data: any;

  @Input() fontSize: any;

  @Input() MobfontSize: any;

  @Input() fontFamily: any;

  @Input() matIcon: any = 'check_circle';

  arry: any=[];
  icon: boolean;

  constructor(
   private sanitizer: DomSanitizer,
   

  ) {
    
  }

  ngOnInit() {

    console.log(this.text);


    if(this.text.includes('ul')){
      this.icon=true;
    }else{
      this.icon=false;
    }

    this.text = this.text.replace(/<ul>/g, '')
    .replace(/<\/?li>/g, '')
    .replace(/<\/?p>/g, '')
    .replace(/<br>/g, '');

    this.arry = this.text.split('</ul>');
    this.arry = this.arry.filter(item => item.trim() !== '');  
  }

}
