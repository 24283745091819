<div *ngIf="align > 0; else noAlign">
    <div [fxFlex]="align"></div>
    <div [style.font-size]="fontSize"
    
    [ngStyle.lt-md]="{ 'font-size': MobfontSize }"  [ngStyle]="{'font-family': data?.websiteFontFamily}" [innerHTML]="trustedHtmlContent"></div>
    <div [fxFlex]="align"></div>
  </div>
  
  <ng-template #noAlign>
    <div [style.font-size]="fontSize" [ngStyle.lt-md]="{ 'font-size': MobfontSize }" [ngStyle]="{'font-family': data?.websiteFontFamily}"   [innerHTML]="trustedHtmlContent"></div>
  </ng-template>
  